import { createSlice } from "@reduxjs/toolkit";

const userReferralDetail = createSlice({
  name: "userReferralDetail",
  initialState: {
    userReferralDetailHistory: [],
    campaign: {},
    loading: false,
    perPage: 1,
    hasMore: false,
    perPageLimit: 4,
  },
  reducers: {
    setuserReferralDetailHistoryDetail: (state, action) => {
      const { count, campaign, totalCount, data } = action.payload;

      state.campaign = campaign;
      const userReferralDetailHistory = [...state.userReferralDetailHistory, ...data];
      state.userReferralDetailHistory = userReferralDetailHistory;
      state.loading = false;
      state.hasMore = userReferralDetailHistory.length < totalCount;
    },
    setLoading: (state, action) => {
      const loading = action.payload;
      state.loading = loading;
      if (loading) state.perPage += 1;
    },
    setCleanReferralId: (state) => {
      state.userReferralDetailHistory = [];
      state.perPage = 1;
    },
  },
});
export const { setuserReferralDetailHistoryDetail, setLoading, incrementPage, setCleanReferralId } =
  userReferralDetail.actions;
export default userReferralDetail.reducer;
