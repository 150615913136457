import { createSlice } from "@reduxjs/toolkit";

const initialProviders = [
  // "netgame",
  "arrowedge",
  "kagames",
  "luckystreak",
  "luckystreak:pragmaticplay",
  "luckystreak:tomhorn",
  "luckystreak:redrake",
  "luckystreak:fugaso",
  "luckystreak:rogue",
  "luckystreak:ethereal",
];

const providerData = createSlice({
  name: "providerData",
  initialState: {
    providerValues: ["all"], // Default to "All provider"
    allProviders: initialProviders, // Store all providers
  },
  reducers: {
    toggleProvider: (state, action) => {
      const value = action.payload;

      if (value === "all") {
        // If "all" is toggled, select only "all"
        state.providerValues = ["all"];
      } else {
        const { providerValues, allProviders } = state;

        if (providerValues.includes(value)) {
          // Deselect the provider
          state.providerValues = providerValues.filter((provider) => provider !== value);

          // If no provider is selected, revert to "all"
          if (state.providerValues.length === 0) {
            state.providerValues = ["all"];
          }
        } else {
          // Add the provider
          state.providerValues = providerValues.filter((val) => val !== "all");
          state.providerValues.push(value);

          // If all providers are selected, switch to "all"
          if (state.providerValues.length === allProviders.length) {
            state.providerValues = ["all"];
          }
        }
      }
    },
    selectAllProviders: (state) => {
      state.providerValues = ["all"];
    },
  },
});

export const { toggleProvider, selectAllProviders } = providerData.actions;
export default providerData.reducer;
