import React from "react";
import FireworksComponent from "./FireCracker";

export default function Test() {
  return (
    <div>
      {/* <FireworksComponent /> */}
      <FireworksComponent backgroundColor="#111" intervalTime={10} />
    </div>
  );
}
