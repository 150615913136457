import { createSlice } from "@reduxjs/toolkit";

const yourRewardDetail = createSlice({
  name: "yourRewardDetail",
  initialState: {
    rewardHistory: [],

    loading: false,
    perPage: 1,
    hasMore: false,
    perPageLimit: 4,
  },
  reducers: {
    setYourRewardDetail: (state, action) => {
      const { count, totalCount, data } = action.payload;
      const rewardHistory = [...state.rewardHistory, ...data];
      state.rewardHistory = rewardHistory;
      state.loading = false;
      state.hasMore = rewardHistory.length < totalCount;
    },
    setLoading: (state, action) => {
      const loading = action.payload;
      state.loading = loading;
      if (loading) state.perPage += 1;
    },
  },
});
export const { setYourRewardDetail, setLoading, incrementPage } = yourRewardDetail.actions;
export default yourRewardDetail.reducer;
