import React, { useEffect, useState } from "react";
import { FaGift } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fortuneWheelEligible, fortuneWheelPrice } from "../../store/api/wheel";
import { setShowEligible, setWheelPrize } from "../../store/ui/promotionWheel";
import storage from "../../services/storage";

export default function WheelApp() {
  const { showEligible } = useSelector((state) => state.promotionWheel);
  const { token } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isBouncing, setIsBouncing] = useState(true);

  const excludedRoutes = [
    "/login",
    "/forgot-password",
    "/resend-email",
    "/new-password",
    "/about",
    "/continue-password",
    "/kyc-info",
    "/privacy-policy",
    "/use-policy-terms",
    "/promo-policy-terms",
    "/contact",
    "/support",
    "/coverage-area",
    "/search",
    "/all-games",
    "/all-games-filter",
    "/signup",
    "/email-signup",
    "/:code",
  ];

  const shouldShowWheelApp =
    location.pathname !== "/" &&
    !excludedRoutes.some((route) => location.pathname.startsWith(route));
  useEffect(() => {
    const token2 = storage.get("xAuthToken");

    if (token2 || token) {
      dispatch(
        fortuneWheelPrice({
          onSuccess: (res) => {
            const transformedData = res.data.data.map((item) => ({
              option: item.name.trim(),
            }));
            dispatch(setWheelPrize(transformedData));
          },
          onError: (error) => {
            console.error(error);
          },
        })
      );

      dispatch(
        fortuneWheelEligible({
          onSuccess: (res) => {
            dispatch(setShowEligible(res?.data?.data?.eligible));
          },
          onError: (error) => {
            console.error(error);
          },
        })
      );
    }
  }, [token]);

  return shouldShowWheelApp && showEligible ? (
    <div className={`wheel-icons ${isBouncing ? "bounce" : ""}`} onClick={() => navigate("/wheel")}>
      <FaGift color={"#fff"} size={25} />
    </div>
  ) : null;
}
