import React, { useEffect, useRef, useState } from "react";
import { Fireworks } from "@fireworks-js/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setReferSetting } from "../store/ui/cashout.slice";

const FireworksComponent = ({
  backgroundColor = "#000",
  intervalTime = 10,
  fireworksOptions = {},

  firstModal,
  secModal,
  setSecModal,
  activeCampaignData,
  activeCampaign,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const handleReferRedirect = () => {
    dispatch(setReferSetting(true));
    Navigate("/settings");
  };
  useEffect(() => {
    let opacity = 0;
    const interval = setInterval(() => {
      opacity = opacity > 0 ? 0 : 0.1;
      ref.current?.updateOptions({
        opacity,
        ...fireworksOptions, // Apply user-defined options dynamically
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [intervalTime, fireworksOptions]);
  const [showMsg, setShowMsg] = useState(true);
  const handleCloseMsg = () => {
    setSecModal(false);
  };
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  console.log("firstModal && secModal && activeCampaign", firstModal && secModal && activeCampaign);
  return (
    <>
      {firstModal && secModal && activeCampaign && (
        <>
          <Fireworks
            ref={ref}
            options={{
              hue: fireworksOptions.hue || { min: 0, max: 345 },
              acceleration: fireworksOptions.acceleration || 1.02,
              brightness: fireworksOptions.brightness || { min: 50, max: 80 },
              decay: fireworksOptions.decay || { min: 0.01, max: 0.03 },
              delay: fireworksOptions.delay || { min: 30, max: 60 },
              explosion: fireworksOptions.explosion || 5,
              flickering: fireworksOptions.flickering || 50,
              intensity: fireworksOptions.intensity || 30,
              friction: fireworksOptions.friction || 0.97,
              gravity: fireworksOptions.gravity || 1.5,
              opacity: fireworksOptions.opacity || 0.5,
              particles: fireworksOptions.particles || 60,
              traceLength: fireworksOptions.traceLength || 3,
              traceSpeed: fireworksOptions.traceSpeed || 10,
              rocketsPoint: fireworksOptions.rocketsPoint || { min: 50, max: 50 },
              lineWidth: fireworksOptions.lineWidth || { explosion: 1, trace: 0 },
              lineStyle: fireworksOptions.lineStyle || "round",
            }}
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "fixed",
              background: backgroundColor,
              zIndex: "100",
            }}
          />

          <div className="cv text-container-popup pulsing-popup text-container-popup-agree">
            <button className="close-popup-btn" onClick={handleCloseMsg}>
              X
            </button>{" "}
            <div className="scroll-container scroll-container2">
              <h1 className="f16 yellow_text">🎉 Referral Race is ON! 🏁</h1>
              <p>
                Start Time: {formatDate(activeCampaignData?.startTime)}(CST)
                <br />
                End Time: {formatDate(activeCampaignData?.endTime)}(CST)
              </p>
              <p>🔥 Join the Race & Win Big Prizes!</p>
              <p>
                🥇 1st Place: ${activeCampaignData?.winningPrize?.first}
                <br />
                🥈 2nd Place: ${activeCampaignData?.winningPrize?.second}
                <br />
                🥉 3rd Place: ${activeCampaignData?.winningPrize?.third}
              </p>
              <p>
                🌟 How It Works: Invite your friends to join the platform! For every friend who:
                Gets KYC Verified ✅ Makes Their First Purchase 🛒 🎯 Your referral count increases,
                boosting your chance to climb the leaderboard!
                <br />
                💬 Remember: The top 3 referrers by the end of the campaign win amazing prizes!
                Don’t miss this chance to shine and win BIG!
                <a
                  onClick={handleReferRedirect}
                  // className="terms-link"
                  style={{
                    color: "#ffd951",
                    marginLeft: "2px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  invite your friends now!
                </a>{" "}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FireworksComponent;
