/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    all: [],
    unread: [],
    paginationData: [],
    paginationTotalCount: 0,
    activeNotification: {},
    loading: false,
    perPage: 1,
    hasMore: false,
    perPageLimit: 10,
    status: "idle",
  },

  reducers: {
    addNotifications: (state, action) => {
      const exists = state.unread.some((notification) => notification._id === action.payload._id);
      if (!exists) {
        state.all.unshift(action.payload);
        state.unread.unshift(action.payload);
      }
    },
    getAllNotifications: (state, action) => {
      const { notifications } = action.payload;
      const unreadNotifications = notifications.filter(
        (notification) => notification.status === "unread"
      );
      return {
        ...state,
        unread: unreadNotifications,
        loading: false,
        all: notifications,
      };
    },
    getAllNotificationPagination: (state, action) => {
      const { notifications, totalCount } = action.payload;
      const newState = {
        ...state,
        paginationData:
          state.perPage === 1 ? notifications : [...state.paginationData, ...notifications],
        loading: false,
        hasMore: state.paginationData.length < totalCount,
        paginationTotalCount: totalCount,
      };
      return newState;
    },

    getSingleNotification: (state, action) => {
      return {
        ...state,
        activeNotification: action.payload,
        loading: false,
      };
    },

    removeNotification: (state, action) => {
      const id = action.payload;
      const findIndex = state.unread.findIndex((item) => item._id === id);
      if (findIndex > -1) {
        state.unread.splice(findIndex, 1);
      }
    },

    setPageLoad: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setLoading: (state, action) => {
      const loading = action.payload;
      return {
        ...state,
        loading,
        perPage: loading ? state.perPage + 1 : state.perPage,
      };
    },
    setAllRead: (state) => {
      return {
        ...state,
        unread: [],
      };
    },
  },
});
export const {
  addNotifications,
  removeNotification,
  getAllNotifications,
  getSingleNotification,
  setLoading,
  getAllNotificationPagination,
  setPageLoad,
  setAllRead,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
