import http from "../../services/http";
import { handleErrors } from "../../helpers/error";
import storage from "../../services/storage";

export const fortuneWheelPrice = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: "v1/games/fortune-wheel/prizes",

        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const fortuneWheelSpin = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: "v1/games/fortune-wheel/spin",

        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const fortuneWheelEligible = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: "v1/games/fortune-wheel/eligible",

        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
