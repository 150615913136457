import { createSlice } from "@reduxjs/toolkit";

const promotionWheel = createSlice({
  name: "promotionWheel",
  initialState: {
    prize: [],
    showEligible: false,
  },
  reducers: {
    setWheelPrize: (state, action) => {
      state.prize = action.payload;
    },
    setShowEligible: (state, action) => {
      state.showEligible = action.payload;
    },
  },
});

export const { setWheelPrize, setShowEligible } = promotionWheel.actions;

export default promotionWheel.reducer;
