import axios from "axios";
import { apiPath } from "../config";

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

axios.interceptors.request.use(
  function (config) {
    const cancelToken = source.token;

    return { ...config, cancelToken };
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const post = ({ url = "", body = {}, headers = {}, queryParams = {}, otherConfigs = {} }) => {
  return axios.post(apiPath + url, body, {
    headers,
    params: queryParams,
    ...otherConfigs,
  });
};

const put = ({ url = "", body = {}, headers = {}, queryParams = {}, otherConfigs = {} }) => {
  return axios.put(apiPath + url, body, {
    headers,
    params: queryParams,
    ...otherConfigs,
  });
};

const get = ({ url = "", headers = {}, queryParams = {}, otherConfigs = {} }) => {
  return axios.get(apiPath + url, {
    headers,
    params: queryParams,
    ...otherConfigs,
  });
};

const deleteRequest = ({ url = "", headers = {}, queryParams = {}, otherConfigs = {} }) => {
  return axios.delete(apiPath + url, {
    headers,
    params: queryParams,
    ...otherConfigs,
  });
};

export default {
  get,
  post,
  put,
  delete: deleteRequest,
};
