import { createSlice } from "@reduxjs/toolkit";

const ReferralCompaigns = createSlice({
  name: "ReferralCompaigns",
  initialState: {
    ReferralCompaignsHistory: [],

    loading: false,
    perPage: 1,
    hasMore: false,
    perPageLimit: 4,
    totalSignup: "",
  },
  reducers: {
    setReferralCompaignsHistoryDetail: (state, action) => {
      const { count, totalCount, data } = action.payload;

      const ReferralCompaignsHistory = [...state.ReferralCompaignsHistory, ...data];
      state.ReferralCompaignsHistory = ReferralCompaignsHistory;
      state.loading = false;
      state.hasMore = ReferralCompaignsHistory.length < totalCount;
    },
    setLoading: (state, action) => {
      const loading = action.payload;
      state.loading = loading;
      if (loading) state.perPage += 1;
    },
    setTotalSignup: (state, action) => {
      state.totalSignup = action.payload;
    },
    ResetReferralCompaignsHistoryDetail: (state, action) => {
      state.ReferralCompaignsHistory = [];
    },
  },
});
export const {
  setReferralCompaignsHistoryDetail,
  setLoading,
  incrementPage,
  setTotalSignup,
  ResetReferralCompaignsHistoryDetail,
} = ReferralCompaigns.actions;
export default ReferralCompaigns.reducer;
