import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import authReducer from "./auth/authState";
import authUser from "./auth/user";
import loginUi from "./ui/loginUi";
import userData from "./ui/userData";
import userCashout from "./ui/cashout.slice";
import providerData from "./ui/SelectedProvider";
// import { setEditUser } from "./auth/eidtProfile";
import eidtUserSlice from "./auth/eidtProfile";
import lobbyGames from "./ui/lobbyGames";
import favoritesGames from "./ui/Favorites.slice";
import filteringLobbyGamesSlice from "./ui/filteringLobbyGames.slice";
import GamesSearchSlice from "./ui/GamesSearch.slice";
import userBillingHistory from "./ui/billingHistory.slice";
import userCashoutHistory from "./ui/cashoutHistory.slice";
import jackpot from "./ui/jackpot";
import notificationsSlice from "./ui/notifications.slice";
import promotionWheel from "./ui/promotionWheel";
import yourRewardDetail from "./ui/yourReward";
import ReferralCompaigns from "./ui/referralCompaigns";
import userReferralDetail from "./ui/userReferralDetail";
const combineReducer = combineReducers({
  auth: authReducer,
  user: authUser,
  login: loginUi,
  userData,
  providerData,
  editUser: eidtUserSlice,
  lobbyGamesResp: lobbyGames,
  favoritesGamesResp: favoritesGames,
  filterLobbyGames: filteringLobbyGamesSlice,
  userGamesSearch: GamesSearchSlice,
  billingHistory: userBillingHistory,
  cashoutHistory: userCashoutHistory,
  userCashout,
  jackpot,
  notifications: notificationsSlice,
  promotionWheel,
  yourRewardDetail,
  ReferralCompaigns,
  userReferralDetail,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
